import { parseJwt } from "./helpers";

//Get current user
const  GetCurrentUser =()=>{
    let user =localStorage.getItem("LogedUser");
    return user;
}
export default GetCurrentUser;


export const parsedUser = () => {
    let user = GetCurrentUser();
    let parsed = parseJwt(user);
    return parsed;
}