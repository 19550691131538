import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

import './app.css';
// pages


import DashboardOverview from "./components/dashboard/DashboardOverview";

import Settings from './components/Settings/Settings';
// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import Preloader from "./components/Preloader";


import Profile from './components/Profile/Profile';
import Login from './components/Profile/Login';
import Register from './components/Profile/Register';
import ForgotPassword from './components/Profile/ForgotPassword';

import BlockchainData from './components/BlockchainData/Index';
import Transactions from './components/Transactions/Index';
import ForexSymbol from './components/Forex/ForexSymbol';
import ForexHistoricalExchange from './components/Forex/ForexHistoricalExchange';
import BTCHistory from './components/BTCHistory/Index';
import BTCHistoryByMinute from './components/BTCHistory/ByMinute';
import Users from './components/Users/Index';



const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar {...props} />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};

export default () => (
  <Switch>


    {/* pages */}
    <RouteWithSidebar exact path={Routes.Dashboard.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />

    <RouteWithSidebar exact path={Routes.BlockchainData.path} component={BlockchainData} />

    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.ForexSymbols.path} component={ForexSymbol} />
    <RouteWithSidebar exact path={Routes.ForexHistoricalExchange.path} component={ForexHistoricalExchange} />
        <RouteWithSidebar exact path={Routes.BTCExchangeData.path} component={BTCHistory} />
        <RouteWithSidebar exact path={Routes.BTCExchangeDataByMinute.path} component={BTCHistoryByMinute} />
        <RouteWithSidebar exact path={Routes.Users.path} component={Users} />

    <RouteWithSidebar exact path={Routes.Profile.path} component={Profile} />
    <Route path={Routes.SignIn.path} component={Login} />
    <Route path={Routes.Register.path} component={Register} />
    <Route path={Routes.ForgotPassword.path} component={ForgotPassword} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
