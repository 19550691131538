import { Card, Row, Col } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import API from '../../common/API';
import { epochToDateConverter, FormatMoney } from '../../common/helpers';
import { useState } from '@hookstate/core';
import moment from 'moment';
import Pagination from '../Common/Pagination';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const Index = props => {

    const state = useState({
        Records: [],
        totalPages: 0,
        totalRecords: 0,
        currentPage: 0,
        recordsPerPage: !props.latest ? 50 : 5,
        From: null,
        Until: null
    })
    useEffect(() => {
        request();
    }, []);

    const request = async () => {
        let query = "";
        query += "Page=" + state.currentPage.value;
        query += "&Quantity=" + state.recordsPerPage.value;
        if (state.From.value !== null) {
            query += "&From=" + moment(state.From.value).unix();
        }

        if (state.Until.value !== null) {
            query += "&To=" + moment(state.Until.value + "T23:59:59").unix();
        }
        var request = await API.getAction("ExchangeRate/List", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            state.Records.set(request.data.response);
            state.totalPages.set(request.data.quantityOfPages);
            state.totalRecords.set(request.data.quantityOfRecords);
        }
    }
    const handleFromChange = val => {
        state.From.set(val);
    }

    const handleUntilChange = val => {
        debugger;

        state.Until.set(val);
    }
    const onPageChanged = async obj => {
        state.currentPage.set(obj);
        await request();
    }
    
    const deleteTransaction =  tx => {
        console.log("Transaction to delete", tx);
        var item = tx.date !== null ? moment(epochToDateConverter(tx.date)).format("MMM DD, YYYY hh:mm A z") : null
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + item + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let url = "ExchangeRate/Delete" ;
                        await API.getAction(url, "id="+tx.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //     onClick: () => alert('Click No')
                }
            ]
        });

     
    }




    return (<>
        <Row>
            <Col xs={12}>
                <Card className="shadow-sm">
                    <Card.Header className="row mx-0">
                        <div className="col">
                            <Card.Title>
                                BTC - USD Historical Exchange Rate (Hourly)

                            </Card.Title>
                        </div>
                        <div className="col-4 text-right">
                            {!props.latest ?
                                <div className="row">
                                    <div className="col-auto">
                                    <div className="input-group  ">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">From</span>
                                        </div>
                                            <input type="date" className="form-control  form-control-sm" value={state.From.value ? state.From.value : ""} onChange={(val) => handleFromChange(val.target.value)} />
                                       
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                <div className="input-group ">
                                     <div className="input-group-prepend">
                                        <span className="input-group-text">To</span>
                                    </div>
                                    <input type="date" className="form-control form-control-sm " value={state.Until.value? state.Until.value: ""} onChange={(val) => handleUntilChange(val.target.value)} />
                                  
                                        </div>
                                    </div>
                                    <div className="col-auto px-0">
                                    <button className="btn btn-sm btn-primary" onClick={() => request()}>
                                        Filter
                                        </button>
                                        </div>
                                    </div>
                                : null}
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive">
                        {state.Records.length > 0 ?
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>High</th>
                                        <th>Open</th>
                                        <th>Low</th>
                                        <th>Volume From</th>
                                        <th>Volume To</th>
                                        <th>Close</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.Records.value.map((item, index) => {
                                        let currentRecord = state.currentPage.value === 0 ? index + 1 : (index + 1) + (state.currentPage.value * state.recordsPerPage.value)
                                        return (<tr key={index}>
                                            <td>
                                                <button className="btn btn-primary btn-sm"
                                                    onClick={() => {
                                                        deleteTransaction(item);
                                                    }}                                                >
                                                    <i className="fa fa-trash" />
                                                </button>
                                            </td>
                                            <td>{currentRecord}</td>
                                            <td>{item.date !== null ? moment(epochToDateConverter(item.date)).format("MMM DD, YYYY hh:mm A z") : null}</td>
                                            <td>USD$ {FormatMoney(item.high, ",", ".")}</td>
                                            <td>USD$ {FormatMoney(item.open, ",", ".")}</td>
                                            <td>USD$ {FormatMoney(item.low, ",", ".")}</td>
                                            <td>USD$ {FormatMoney(item.volumeFrom, ",", ".")}</td>
                                            <td>USD$ {FormatMoney(item.volumeTo, ",", ".")}</td>
                                            <td>USD$ {FormatMoney(item.close, ",", ".")}</td>
                                            <td className="text-right">

                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            : null}
                    </Card.Body>
                    {!props.latest ?
                        <Card.Footer>
                            <Pagination totalPages={state.totalPages.value} currentPage={state.currentPage.value} onPageChanged={onPageChanged} />
                        </Card.Footer>
                        : null}
                </Card>
            </Col>
        </Row>
    </>);
}

export default Index;