import Profile3 from "../assets/img/team/profile-picture-3.jpg";

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}



export const userAvatar = userName => {
    if (userName === null || userName === undefined) {
        return Profile3;
    }
    let spli = userName.split(' ');
    let initials = "";
    spli.forEach(element => {
        if (initials.length < 2) {
            initials += element.substring(0, 1).toUpperCase();
        }
    });

    let path = "https://via.placeholder.com/150/000000/FFFFFF/?text=" + initials;
    return path;
}


export const epochToDateConverter = date => {
    return date * 1000;
}



//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = function (n, c, decimal, t) {

    var c = isNaN(c = Math.abs(c)) ? 2 : c,
      decimal = decimal == undefined ? "." : decimal,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? decimal + Math.abs(n - i).toFixed(c).slice(2) : "");
  };