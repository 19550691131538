//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "VirtualOffice",
    Name: "virtualoffice",
    GoogleMapsAPIKey : "AIzaSyDDAfOZ6JftGUVNA2nesegbs4gNhGs1CdM",
    baseURl:"https://api.bitcoin-reports.com/api/",
    TrelloApiKey : "7c3e5aac59ae8c76f63ad33b2e0def9f",
    TrelloSecretOAuth: "a67399904be40ff9356e5cffa461f8bc4735ae35dfcdbb4ebb9d38ffeb23dd6c",
    TrelloToken: "aab0957f79643a981a3c86fa2b3ba007d19b47bef5d3c2f3d9e2f61c2efcef0c"
};

export default Settings;
