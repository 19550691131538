import { Card, Row, Col } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import API from '../../common/API';
import { epochToDateConverter, FormatMoney } from '../../common/helpers';
import { useState } from '@hookstate/core';
import moment from 'moment';
import Pagination from '../Common/Pagination';
const Index = props => {

    const state = useState({
        Records: [],
        totalPages: 0,
        totalRecords: 0,
        currentPage: 0,
        recordsPerPage: !props.latest? 50:5,
        From: null,
        Until: null,
        UI: {
            isLoading: false
        }
    })
    useEffect(() => {
        request();
    }, []);

    const request = async () => {
        let query = "";
        query += "Page=" + state.currentPage.value;
        query += "&Quantity=" + state.recordsPerPage.value;
        if (state.From.value !== null) {
            query += "&From=" + moment(state.From.value).unix();
        }

        if (state.Until.value !== null) {
            query += "&To=" + moment(state.Until.value).unix();
        }
        var request = await API.getAction("Blocks/List", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            state.Records.set(request.data.response);
            state.totalPages.set(request.data.quantityOfPages);
            state.totalRecords.set(request.data.quantityOfRecords);
        }
    }
    const handleFromChange = val => {
        state.From.set(val);
    }

    const handleUntilChange = val => {
        state.Until.set(val);
    }
    const onPageChanged = async obj => {
        state.currentPage.set(obj);
        await request();
    }


    return (<>
        <Row>
            <Col xs={12}>
                <Card className="shadow-sm">
                    <Card.Header className="row mx-0">
                        <div className="col">
                            <Card.Title>
                                Blockchain historic

                            </Card.Title>
                        </div>
                        <div className="col-4 text-right">
                            {!props.latest ?
                                <div className="input-group ml-auto">
                                    <label className="px-4">From:</label>
                                    <input type="date" className="form-control" value={state.From.value} onChange={(val) => handleFromChange(val.target.value)} />
                                    <label className=" px-4">To:</label><input type="date" className="form-control" value={state.Until.value} onChange={(val) => handleUntilChange(val.target.value)} />
                                    <button className="btn btn-sm btn-primary" onClick={() => request()}>
                                        Filters
                                    </button>
                                </div>
                                : null}
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive">
                        {state.Records.length > 0 ?
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Hash</th>
                                        <th>Height</th>
                                        
                                        <th>Size</th>
                                        <th>Weigth</th>
                                        
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.Records.value.map((item, index) => {
                                        let currentRecord = state.currentPage.value === 0 ? index + 1 : (index+1 ) + (state.currentPage.value * state.recordsPerPage.value)
                                        return (<tr key={index}>
                                         
                                            <td>{currentRecord}
                                               
                                            </td>
                                            <td>{item.timestamp !== null ? moment(epochToDateConverter(item.timestamp)).format("MMM DD, YYYY hh:mm A Z") : null}</td>
                                            <td className="text-truncate">{item.hash}<br/>
                                                <small className="text-muted">Prev. {item.previousHash}</small>
                                            </td>
                                            <td>{FormatMoney(item.height, ",", ".")}</td>
                                            
                                            <td>{FormatMoney(item.size, ",", ".")}</td>
                                            
                                            <td>{FormatMoney(item.weight, ",", ".")}</td>
                                            <td className="text-right">

                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            : null}
                    </Card.Body>
                    {!props.latest ?
                        <Card.Footer>
                            <Pagination totalPages={state.totalPages.value} currentPage={state.currentPage.value} onPageChanged={onPageChanged} />
                        </Card.Footer>
                        : null}
                </Card>
            </Col>
        </Row>
    </>);
}

export default Index;