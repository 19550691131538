import { Card, Row, Col } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import API from '../../common/API';
import { useState } from '@hookstate/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
const ForexSymbol = props => {

    const state = useState({
        Records: []
    })
    useEffect(() => {
        RequestSymbols();
    }, []);

    const RequestSymbols = async () => {
        var request = await API.getAction("Forex/GetSymbols", "");
        console.log("Response from forex", request)
        if (request.data.status === "ok") {
            state.Records.set(request.data.response);
        }
    }

    const RefreshSymbol = async (symbol) => {
        var request = await API.getAction("Forex/HistoryFromAPI", "Symbol="+ symbol.symbol);
        console.log("Response from symbol update", request)
        if (request.data.status === "ok") {
         //   state.Records.set(request.data.response);
        }
    }

    return (<>
        <Row>
            <Col xs={12}>
                <Card className="shadow-sm">
                <Card.Header className="row mx-0">
                        <div className="col">
                            <Card.Title>
                                Forex Symbols

                            </Card.Title>
                        </div>
                        <div className="col-4 text-right">
                            {/* <div className="input-group ml-auto">
                                <label className="px-4">From:</label>
                                <input type="date" className="form-control" value={state.From.value} onChange={(val)=>handleFromChange(val.target.value)}/>
                                <label className=" px-4">To:</label><input type="date" className="form-control" value={state.Until.value} onChange={(val) => handleUntilChange(val.target.value)} />
                                <button className="btn btn-sm btn-primary" onClick={()=>request()}>
                                    Filters
                                </button>
                            </div> */}
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive">
                        {state.Records.length > 0 ?
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Symbol</th>
                                        <th>Latest Date Stored</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.Records.value.map((item, index) => {
                                        return (<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link to={Routes.ForexHistoricalExchange.clean+item.id+"?Symbol="+ item.symbol}>
                                                {item.name}
                                                </Link>
                                            </td>
                                            <td>{item.symbol}</td>
                                            <td>{item.lastDayRequested !== null ? moment(item.lastDayRequested).format("MMM DD, YYYY") : null}</td>
                                            <td className="text-right">
                                                <button className="btn btn-primary" 
                                                onClick={()=>RefreshSymbol(item)}
                                                >Update</button>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            : null}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>);
}

export default ForexSymbol;