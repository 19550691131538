import { Card, Row, Col } from '@themesberg/react-bootstrap';
import React, {useEffect} from 'react';
import { useState } from '@hookstate/core';
import Pagination from '../Common/Pagination';
import API from '../../common/API';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { epochToDateConverter, FormatMoney } from '../../common/helpers';
import { Routes } from '../../routes';
const ForexHistoricalExchange = props => {
    
    let { id } = useParams();
    const state = useState({
        Records: [],
        totalPages: 0,
        totalRecords: 0,
        currentPage: 0,
        recordsPerPage: 50,
        From: null,
        Until: null
    })
    const Symbol = props.match.params.Symbol;
    useEffect(() => {
        request();
    }, [])
    const onPageChanged = async obj => {
        state.currentPage.set(obj);
        await request();
    }
    const handleFromChange = val => {
        state.From.set(val);
    }

    const handleUntilChange = val => {
        state.Until.set(val);
    }
    const request = async () => {
        let query = "SymbolId="+id;
        
        query += "&Page=" + state.currentPage.value;
        query += "&Quantity=" + state.recordsPerPage.value;
        if (state.From.value !== null) {
            query += "&From=" + moment(state.From.value).unix();
        }

        if (state.Until.value !== null) {
            query += "&To=" + moment(state.Until.value).unix();
        }
        var request = await API.getAction("forex/List", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            state.Records.set(request.data.response);
            state.totalPages.set(request.data.quantityOfPages);
            state.totalRecords.set(request.data.quantityOfRecords);
        }
    }
    return (<>
     <div className="row">
                <nav aria-label="breadcrumb" className="mb-2">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/"><i className="far fa-home-alt"></i></Link></li>

                        <li className="breadcrumb-item"><Link to={Routes.ForexSymbols.path}>Forex Symbols</Link></li>
                        <li className="breadcrumb-item">Details</li>
                    </ol>
                </nav>
                
            </div>
        <Row>
            <Col xs={12}>
                <Card className="shadow-sm">
                <Card.Header className="row mx-0">
                        <div className="col">
                            <Card.Title>
                                Forex Historical Exchange {Symbol}

                            </Card.Title>
                        </div>
                        <div className="col-4 text-right">
                            <div className="input-group ml-auto">
                                <label className="px-4">From:</label>
                                <input type="date" className="form-control"
                                    value={state.From.value ? state.From.value : ""} onChange={(val) => handleFromChange(val.target.value)} />
                                <label className=" px-4">To:</label>
                                <input type="date" className="form-control" value={state.Until.value ? state.Until.value : ""} onChange={(val) => handleUntilChange(val.target.value)} />
                                <button className="btn btn-sm btn-primary" onClick={()=>request()}>
                                    Filters
                                </button>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Open</th>
                                    <th>High</th>
                                    <th>Low</th>
                                    <th>Close</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {state.Records.value.map((item, index) => {
                                        return (<tr key={index}>
                                            
                                             <td>{item.date !== null ? moment(epochToDateConverter(item.timeUnixFormatUtc)).format("MMM DD, YYYY hh:mm A z") : null}</td> 
                                            <td>{item.symbol} {FormatMoney(item.open, ",", ".")}</td>
                                            <td>{item.symbol} {FormatMoney(item.high, ",", ".")}</td>
                                            <td>{item.symbol} {FormatMoney(item.low, ",", ".")}</td>
                                            <td>{item.symbol} {FormatMoney(item.close, ",", ".")}</td>
                                          
                                        </tr>)
                                    })}
                                </tbody>
                        </table>
                    </Card.Body>
                    <Card.Footer>
                        <Pagination totalPages={state.totalPages.value} currentPage={state.currentPage.value} onPageChanged={onPageChanged} />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </>);
}

export default ForexHistoricalExchange;