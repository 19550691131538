
export const Routes = {
    // pages
    Home : {path:"/#"},
    Dashboard: { path: "/" },
    Settings: { path: "/settings" },

    ForexSymbols: { path:"/ForexSymbols"},
    ForexHistoricalExchange: { path:"/ForexHistoricalExchange/:id", clean:"/ForexHistoricalExchange/"},
    Transactions: { path: "/Transactions" },
    BlockchainData: { path: "/BlockchainData" },
    
    NotFound: { path: "/examples/NotFound" },
    ServerError: { path: "/examples/ServerError" },

    BTCExchangeData: {path: "/btcExchangeData"},
    BTCExchangeDataByMinute: {path: "/btcExchangeDataByMinute"},
    Profile: {path:"/profile"},
    SignIn: {path:"/signin"},
    Register: {path:"/Register"},
    ForgotPassword: {path: "/ForgotPassword"},
    ResetPassword: { path: "/ResetPassword" },

    Users: {path: "/users"}
};